import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import styles

import useRequest from "../../hooks/useRequest";
import ImagePreview from "../../components/ImagePreview/ImagePreview";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import Select from "react-select";

const SendNotification = () => {
  const history = useHistory();

  const { response: responseUpdate, request: requestUpdate } = useRequest();
  const { request: requestCities, response: responseCities } = useRequest();
  const [touchedError, setTouchedError] = useState(false);
  const [displayImage, setDisplayImage] = useState();
  const [image, setImage] = useState();

  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    setError,
    getValues,
    control,
    clearErrors,
  } = useForm();

  useEffect(() => {
    requestCities("GET", "admin/vendor/getCity");
  }, []);

  useEffect(() => {
    if (responseCities) {
      if (responseCities.status && responseCities.data) {
        setCities(responseCities.data);
      }
    }
  }, [responseCities]);

  const onSubmit = (data) => {
    const { city, title, description } = data;
    const formData = new FormData();
    formData.append("cityId", city);
    formData.append("topicNotification", title);
    formData.append("messageNotification", description);

    if (displayImage) {
      if (data.image) {
        formData.append("image", data.image);
      }
    }

    requestUpdate("POST", "admin/user/sendBulkNotificationUser", formData);
  };

  useEffect(() => {
    if (responseUpdate) {
      if (responseUpdate.status && responseUpdate.message) {
        toast.success(responseUpdate?.message);
        history.push("/dashboard");
      }
    }
  }, [responseUpdate]);

  const handleImage = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      setDisplayImage(URL.createObjectURL(event.target.files[0]));
      setError("image", "");
      setValue("image", event.target.files[0]);
    } else {
      setDisplayImage("");
      setImage("");
      setValue("image", "");
    }
  };

  const handleRemoveImage = () => {
    if (document.getElementById("image")) {
      document.getElementById("image").value = "";
    }
    setDisplayImage(null);
    setValue("image", null);
    setImage("");
  };
  const [description, setDescription] = useState(getValues("description"));

  const handleEditorChange = (e) => {
    setDescription(e.target.value);
    setValue("description");
  };
  return (
    <div className="ms-content-wrapper">
      <div className="row">
        <div className="col-md-12">
          <Breadcrumb
            title={"Add"}
            links={[
              { to: "/dashboard", name: "Home" },
              { to: "/notifications", name: "Notifications" },
            ]}
          />
        </div>
        <div className="col-xl-6 col-md-12">
          <div className="ms-panel ms-panel-fh">
            <div className="ms-panel-header">
              <h6>Send Bulk Notifcations</h6>
            </div>
            <div className="ms-panel-body">
              <form
                className="needs-validation clearfix"
                noValidate
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="form-row">
                  <div className="col-md-6 mb-3">
                    <label htmlFor="city">
                      City<span className="field-required">*</span>
                    </label>
                    <Select
                      {...register("city", {
                        required: true,
                      })}
                      options={
                        cities &&
                        cities.length > 0 &&
                        cities.map((obj) => ({
                          label: obj.name,
                          value: obj._id,
                        }))
                      }
                      required={true}
                      name="cities"
                      onChange={(value) => {
                        setValue("city", value.value);
                        setCity(value.value);
                      }}
                    />
                    {errors.city && city == "" && (
                      <div
                        className="invalid-field"
                        style={{ position: "initial" }}
                      >
                        The City field is required.
                      </div>
                    )}
                  </div>
                  <div className="col-md-6 mb-3">
                    <label htmlFor="title">
                      Title<span className="field-required">*</span>
                    </label>
                    <div className="input-group">
                      <input
                        type="text"
                        id="title"
                        placeholder="Title"
                        className={`form-control ${
                          touchedError
                            ? errors.title
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("title", {
                          required: true,
                        })}
                      />
                      {errors.title?.type === "required" && (
                        <div className="invalid-field">
                          The title field is required.
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 mb-3">
                    <label htmlFor="description">
                      Message<span className="field-required">*</span>
                    </label>
                    <div className="input-group mb-5">
                      {/* <CKEditor
                        {...register("description", {
                          required: true,
                        })}
                        initData={getValues("description")}
                        config={{
                          extraAllowedContent:
                            "p(*)[*]{*};div(*)[*]{*};li(*)[*]{*};ul(*)[*]{*};i(*)[*]{*}",
                          allowedContent: true,
                          protectedSource: [/<i[^>]*><\/i>/g],
                          // removeEmpty: { i: false },
                        }}
                        onChange={({ editor }) => {
                          const data = editor.getData();
                          setValue("description", data);
                          clearErrors("description");
                        }}
                      /> */}

                      <textarea
                        id="description"
                        value={description} // Controlled by React state
                        name="description"
                        rows="5"
                        cols="30"
                        onChange={(e) => {
                  console.log('dddddd',e.target.value)
                          setDescription(e.target.value);
                          setValue("description", e.target.value);
                        }}
                        className={`form-control ${
                          touchedError
                            ? errors.description
                              ? "is-invalid"
                              : "is-valid"
                            : ""
                        }`}
                        {...register("description", { required: true })}
                      />
                      
                      {errors.description?.type === "required" && (
                        <div className="invalid-field">
                          The description field is required.
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 mb-3">
                    <label htmlFor="image"> Image</label>
                    <div className="custom-file">
                      <Controller
                        control={control}
                        name="image"
                        render={({ field: { onChange, value, ref } }) => (
                          <input
                            type="file"
                            id="image"
                            onChange={handleImage}
                            style={{ display: "none" }}
                            inputRef={ref}
                            accept="image/*"
                            className="custom-file-input"
                          />
                        )}
                      />
                      <label className="custom-file-label" htmlFor="image">
                        Images
                      </label>
                      <div className="invalid-feedback">
                        Example invalid custom file feedback
                      </div>
                    </div>
                  </div>

                  <button
                    className="btn btn-primary mt-4 d-block w-100"
                    onClick={() => setTouchedError(true)}
                    type="submit"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
        {(displayImage || image) && (
          <div className="col-xl-6 col-md-12">
            <div className="row">
              <div className="col-md-12">
                <div className="ms-panel">
                  <div className="ms-panel-header">
                    <h6>Image </h6>
                  </div>
                  <div className="ms-panel-body">
                    <ImagePreview displayImage={displayImage} image={image} />
                  </div>

                  <div className="ms-panel-header ">
                    <label
                      className="btn btn-primary mx-2"
                      onClick={handleRemoveImage}
                    >
                      Remove
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SendNotification;
